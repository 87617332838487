import { Injectable, Injector } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { isApiUrl, mapValuesDeep } from '../../../utils';
import { isDate as _isDate, isString as _isString, isEqual as _isEqual } from 'lodash-es';
import { GlobalService, CacheService } from 'src/app/modules/shared/services';
import * as moment from 'moment-timezone';  // most use this form so I can use timezone
import { formatDate } from '@angular/common';


export interface requestIdentifiers {
  url_regex: string;
  post_body_path: string[];
}

export interface timeStampRequestModifiers extends requestIdentifiers {
  timeZone: string;
}

export interface cacheRequestModifiers extends requestIdentifiers {
  uid: string;
}

function cachedRequest(request): cacheRequestModifiers | undefined {
  return undefined;
}


/* here is the issue:
   there are a number of date fields in api which are actually timestamps. So when javascript sends such a date fields upstream the date will look like yyyy-mm-dd:00:00:00.
   Now when we retrieve that date value it will be converted back to a local date value that will often be a different date depending how far the js client is form utc.

   solutions:
   1) make user put in full timestamp for all timestamp fields, and have the client convert it to utc time on upstream sends, then do the reverse on downstream.
   2) faster solution to start: convert all such timestamp fields here in interceptor to utc equivalent of nyc local time.
       I am not converting to the local of user since the company trades in nyc time.

 */

function convertMysqlDate(input: string, SPECIAL_FALSE: Number, path: string[]): Number | Date {
  if (!_isString(input)) { return SPECIAL_FALSE; }
  const isMySqlDate = (input_: string): boolean => input_.match(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/) ? true : false;
  const isMySqlTimeStamp = (input_: string): boolean => input_.match(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-4]|[01][0-9])(:[0-6][0-9]){2}$/) ? true : false;
  if (isMySqlTimeStamp(input)) {
    const conversion = moment.utc(input);
    const conversionLocal = conversion.toDate();
    return conversionLocal;
  }
  if (isMySqlDate(input)) { return new Date(formatDate(input, "M/dd/yyyy", 'en-US')) }
  return SPECIAL_FALSE;
}

/**
 * @todo need to throw proper error if timeStampFieldList is not valid
 * @param dateVal
 * @param path
 * @param timeStampFieldList
 * @param url
 */
function convertToLocalTimeStamp(dateVal, path: string[], timeStampFieldList: timeStampRequestModifiers[], url: string) {
  /*
    we look at both the url and the request body to determine if we need to convert see timeStampFieldList
  */

  const matches = timeStampFieldList.filter(_ => _.url_regex && url && url.match(_.url_regex)) // remember there is no need to separately look for wildcards just take advantage of inherent regex
    .filter(_ => _isEqual(_.post_body_path.slice(-1 * path.length), path));
  if (matches.length > 1) { throw 'multiple matches for converting to tz'; }
  return matches.length > 0 ? moment.tz(dateVal, matches[0].timeZone) : undefined;
}

@Injectable()
export class TypeConverterInterceptor implements HttpInterceptor {

  constructor(private injector: Injector, private globalService: GlobalService, private cacheService: CacheService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!isApiUrl(req.url)) { return next.handle(req); }
    if (this.globalService.tsw_config.misc.api_ignore_convert_to_local_timestamp.some(pattern => req.url.match(new RegExp(pattern)))) {
      console.log('ignoring time converter');
      return next.handle(req);
    }


    if (req.body) {
      req = req.clone({
        body: mapValuesDeep(req.body, (req_param: any, myFalse: number, path: string[]) => {
          if (!_isDate(req_param)) { return req_param; }
          const localTimeStamp = convertToLocalTimeStamp(req_param, path, this.globalService.tsw_config.misc.api_convert_to_local_timestamp, req.url);
          return localTimeStamp || formatDate(req_param, 'yyyy-MM-dd', 'en-US', 'utc');
        }
          , -.7609)
      });
    }


    return next.handle(req).pipe(map(event => {
      if (event instanceof HttpResponse) {
        event = event.clone({ body: mapValuesDeep(event.body, convertMysqlDate, -.7609) });
      }
      return event;
    })
    );
  }
}
