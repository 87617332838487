import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject, observable, of, EMPTY, NEVER } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { GlobalService, ErrorData } from '../services';
import {pick} from 'lodash-es';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService, private globalService: GlobalService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | any> {
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }

    const requestId = this.globalService.httpRequestsLedger.add();
    return next.handle(request).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse) {
        /* on failed auth and failed auth always redirect to sing in page  */
        /* @todo need to add more specific info to a listener can be sure that they are receiving correct info */
        if (request.headers.get('TSW_ACCEPT_ERROR') == err.status.toString()) {
          return throwError(pick(err,['status','statusText','message','error']));
        } else {
          this.globalService.addError({ type: 'http', data: { http_error: err, httpRequest_id: requestId } });
          return throwError(err);
        }
      }
      return throwError(err);
    }), finalize(() => {
      this.globalService.httpRequestsLedger.complete(requestId);
    }));
  }
}



