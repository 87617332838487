import { Injectable } from '@angular/core';
import { getFunctions, httpsCallable } from 'firebase/functions';


@Injectable({ providedIn: 'root' })
export class PacsTokenService {

    constructor() { }



    private cachedToken: string;
    private cachedTokenExpires: number = undefined;

    private async getToken(): Promise<string> {
        if (this.cachedTokenExpires && (this.cachedTokenExpires - Date.now()) > 10000) {
            return this.cachedToken;
        }
        else {
            const { data } = await httpsCallable<unknown, { token: string, exp: number }>(getFunctions(), 'getDcmUploadToken')()
            this.cachedToken = data.token;
            this.cachedTokenExpires = data.exp;
            return data.token;
        }

    }


    public getViewToken(studyInstanceUid: string): Promise<string> {
        return this.getToken();
    }

}