import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { getAuth, getIdTokenResult } from "firebase/auth";
import { FirebaseAuthService } from 'src/app/modules/shared/services/fbauth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthorizedGuard {
  constructor(private router: Router, private authService: FirebaseAuthService,) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    console.log('we mad it here')
    const hasAppAccess = environment.transcribe_access;
    return getIdTokenResult(getAuth().currentUser).then((token_result) => {
      if (!token_result) {

        this.authService.setPostAuthUrl([state.url]);

        return this.router.createUrlTree(['home'], { queryParams: { login: 1 } });
      }

      return true;
    });
  }

}
