import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Injectable } from "@angular/core";

@Injectable()
export class UnauthorizedGuard  {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
      let isAuthorize = this.authService.checkValidUserAuthData();
      
      if (isAuthorize) {
        this.router.navigate(['']);

        return false;
      } 

      return true;
  }
}

