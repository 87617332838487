import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { PacsTokenService } from 'src/app/modules/shared/services/dicom/pacs-token.service';
import { environment } from 'src/environments/environment';
import { isApiUrl } from '../../../utils';
import { FirebaseAuthService } from '../services/fbauth.service';
import { getAuth, getIdToken } from 'firebase/auth';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {


  constructor(private fbAuth: FirebaseAuthService, private pacsTokenService: PacsTokenService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const getPacsToken = () => this.pacsTokenService.getViewToken('');

    const isFireBaseUrl = (url: string) => url.startsWith(environment.cloudFunctionsUrl);
    const isPacsBaseUrl = (url: string) => url.startsWith(environment.pacsBaseUrl);

    /* stop cycle with refresh token  <-- outdated */
    if (!isApiUrl(request.url) && !isFireBaseUrl(request.url) && !isPacsBaseUrl(request.url)) {

      return next.handle(request);
    }
    const token_promise = isPacsBaseUrl(request.url) ? getPacsToken() : getIdToken(getAuth().currentUser);
    return from(token_promise).pipe(

      concatMap(token => {
        const isFirebaseAdminRequest = (url: string) => (['claimsApi'].filter(item => url.endsWith(item)));

        request = request.clone({

          setHeaders: !isFirebaseAdminRequest(request.url) ? { 'authorization-firebase': `Bearer ${token}` } : {
            authorization: `Bearer ${token}`,
          }

        });
        return next.handle(request);
      }));

  }


}


